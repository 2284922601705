<template>
    <div>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-card-title>{{ $t('generic.lang_dailyOverview') }}</v-card-title>
                    <v-card-subtitle>{{ $t('generic.lang_currentCheckout') }}({{ $t('generic.lang_kasse') }} {{api.auth.cashierID}})</v-card-subtitle>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn :disabled="loading" :loading="loading" @click="getCashiersReport" icon>
                    <v-icon>refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <v-skeleton-loader
                    v-if="loading"
                    ref="skeleton"
                    type="article"
                    class="mx-auto"
            ></v-skeleton-loader>

            <v-card-text style="margin-top: 20px;" v-else>
                <div class="row">
                    <!-- Netto Umsatz -->
                    <div class="col-sm-3 col-xl-3">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <div class="icon-wrapper rounded-circle">
                                <div class="icon-wrapper-bg opacity-10 bg-success"></div>
                                <v-icon class="text-dark opacity-8">euro</v-icon>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">{{ $t('generic.lang_netSales') }}</div>
                                <div class="widget-numbers">{{ data.totalNetto | currency}}</div>
                            </div>
                        </div>
                        <div class="divider m-0 d-md-none d-sm-block"></div>
                    </div>

                    <div class="col-sm-3 col-xl-3">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <div class="icon-wrapper rounded-circle">
                                <div class="icon-wrapper-bg opacity-10 bg-success"></div>
                                <v-icon class="text-dark opacity-8">euro</v-icon>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">{{ $t('erp.lang_grossSales') }}</div>
                                <div class="widget-numbers">{{ data.totalBrutto | currency }}</div>
                            </div>
                        </div>
                        <div class="divider m-0 d-md-none d-sm-block"></div>
                    </div>

                    <div class="col-sm-3 col-xl-3">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <div class="icon-wrapper rounded-circle">
                                <div class="icon-wrapper-bg opacity-10 bg-warning"></div>
                                <v-icon class="text-dark opacity-8">people</v-icon>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">{{ $t('generic.lang_items_customers') }}</div>
                                <div class="widget-numbers">{{ waresPerCustomer }}</div>
                            </div>
                        </div>
                        <div class="divider m-0 d-md-none d-sm-block"></div>
                    </div>

                    <div class="col-sm-3 col-xl-3">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <div class="icon-wrapper rounded-circle">
                                <div class="icon-wrapper-bg opacity-10 bg-info"></div>
                                <v-icon class="text-dark opacity-8">money</v-icon>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">{{ $t('generic.lang_barumsaetze') }}</div>
                                <div class="widget-numbers">{{ data.totalCash | currency }}</div>
                            </div>
                        </div>
                        <div class="divider m-0 d-md-none d-sm-block"></div>
                    </div>

                    <div class="col-sm-3 col-xl-3">
                        <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
                            <div class="icon-wrapper rounded-circle">
                                <div class="icon-wrapper-bg opacity-10 bg-info"></div>
                                <v-icon class="text-dark opacity-8">credit_card</v-icon>
                            </div>
                            <div class="widget-chart-content">
                                <div class="widget-subheading">{{ $t('generic.lang_ecSales') }}</div>
                                <div class="widget-numbers">{{ data.totalCard | currency }}</div>
                            </div>
                        </div>
                        <div class="divider m-0 d-md-none d-sm-block"></div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<style scoped>
    .widget-subheading {
        color: black !important;
    }

    .widget-numbers {
        color: black !important;
        font-size: 25px !important;
    }

    /* REMOVE THE FU.... Z-INDEX */
    .widget-chart-content, .icon-wrapper {
        z-index: 0!important;
    }

    .col-sm-6, .col-sm-3, .col-xl-3 {
        margin-top: -50px !important;
    }
</style>

<script>
    import {mapState} from "vuex";

    export default {
        name: "CurentCashierDailyReport",

        data() {
            return {
                loading: true,
                data: null
            }
        },

        computed: {
            ...mapState([
                'api'
            ]),
            waresPerCustomer() {
                if(this.loading)
                    return 0;

                if(this.data === null)
                    return 0;

                if(this.data.soldItems === 0 || this.data.invoices === 0)
                    return 0;

                return Math.floor((this.data.soldItems / this.data.invoices));
            },
        },

        mounted() {
            this.getCashiersReport()
        },

        methods: {
            getCashiersReport() {
                this.loading = true;

                this.axios.post("/get/dashboard/currentCashierDaily", {
                    cashierID: this.api.auth.cashierID
                }).then((res) => {
                    if(res.data.success) {
                        this.data = res.data.data;
                    }

                    this.loading = false;
                });
            }
        }
    }
</script>