<template>
  <div>
    <button @click="migrateSales"
            class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-1"
            type="button"
    >
      <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
      MIGRATE SALES
    </button>

    <button @click="migrateDailyReports"
            class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-1"
            type="button"
    >
      <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
      MIGRATE DAILY REPORTS
    </button>

    <button @click="migrateMonthlyReports"
            class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-1"
            type="button"
    >
      <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
      MIGRATE MONTHLY REPORTS
    </button>
    <button @click="migrateItemSupplier"
            class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-1"
            type="button"
    >
      <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
      MIGRATE ITEM SUPPLIER
    </button>

    <button @click="migrateCashBook"
            class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-1"
            type="button"
    >
      <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
      MIGRATE CASHBOOK
    </button>
    <button @click="migratePrinters"
            class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-1"
            type="button"
    >
      <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
      MIGRATE PRINTER
    </button>
    <button @click="migrateExtrasItem"
            class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-1"
            type="button"
    >
      <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
      MIGRATE EXTRA ITEMS
    </button>
    <button @click="migrateExtrasItemGroup"
            class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-1"
            type="button"
    >
      <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
      MIGRATE EXTRA ITEMGROUP
    </button>

    <button @click="migrateFreetext"
            class="btn-shadow d-inline-flex align-items-center btn btn-success pl-1 ma-1"
            type="button"
    >
      <v-icon class="mr-1 white--text pa-0" icon="plus">add</v-icon>
      MIGRATE FREETEXT
    </button>
  </div>
</template>

<script>
import {ENDPOINTS} from "../../config";

export default {
  name: "MigrationTest",
  data() {
    return {
      items2Migrate: 0,
      dailyReports2Migrate: 0,
      itemSupplier2Migrate: 0,
    }
  },
  methods: {

    getSyncData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.MIGRATE.INFO).then(response => {
        self.items2Migrate = response.data.saleMigrationCount;
        self.dailyReports2Migrate = response.data.dailyReportMigrationCount;
        self.itemSupplier2Migrate = response.data.itemCount;

      })
    },
    migrateItemSupplier: function () {


      let itemSupplier2Migrate = this.itemSupplier2Migrate;
      let loops = Math.floor((itemSupplier2Migrate / 800)) + 1;
      let self = this;
      for (let i = 0; i < loops; i++) {
        setTimeout(function () {
          self.axios.post(ENDPOINTS.MIGRATE.ITEMSUPPLIERS, {
            start: (i * 800),
            end: (((i + 1) * 800) - 1)
          });
        }, 5000 * i);

      }
    },
    migratePrinters: function () {
      this.axios.post(ENDPOINTS.MIGRATE.PRINTER).then(response => {

      })
    },
    migrateExtrasItem: function () {
      this.axios.post(ENDPOINTS.MIGRATE.EXTRAITEMS).then(response => {

      })
    },
    migrateExtrasItemGroup: function () {
      this.axios.post(ENDPOINTS.MIGRATE.EXTRAITEMGROUPS).then(response => {

      })
    },

    migrateFreetext: function () {
      this.axios.post(ENDPOINTS.MIGRATE.FREETEXT).then(response => {

      })
    },

    migrateCashBook: function () {
      this.axios.post(ENDPOINTS.MIGRATE.CASHBOOK).then(response => {

      })
    },
    async migrateDailyReports() {
      let dailyReports2Migrate = this.dailyReports2Migrate;
      let loops = Math.floor((dailyReports2Migrate / 20)) + 1;
      for (let i = 0; i < loops; i++) {
        await this.axios.post(ENDPOINTS.MIGRATE.DAILY, {
            start: (i * 20),
            end: (((i + 1) * 20) - 1)
          });
      }
    },
    migrateMonthlyReports: function () {
      let self = this;
      self.axios.post(ENDPOINTS.MIGRATE.MONTHLY, {});
    },
    migrateSales: function () {
      let self = this;
      let items2Migrate = this.items2Migrate;
      let loops = Math.floor((items2Migrate / 500)) + 1;

      for (let i = 0; i < loops; i++) {
        setTimeout(function () {
          self.axios.post(ENDPOINTS.MIGRATE.SALES, {
            start: (i * 500),
            end: (((i + 1) * 500) - 1)
          });
        }, 5000 * i);

      }
    },
  },
  mounted() {
    this.getSyncData();
  }
}
</script>

<style scoped>

</style>