<template>
    <div>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-card-title>{{ $t('generic.lang_userOverview') }}</v-card-title>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn :disabled="loading" :loading="loading" @click="getUsersReport" icon>
                    <v-icon>refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <v-divider style="margin-top: 5px;"></v-divider>

            <v-data-table
                    :loading="loading"
                    :headers="tableHeaders"
                    :items="data"
                    :items-per-page="30"
                    class="elevation-1"
                    hide-default-footer
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td>{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.totalBrutto | currency }}</td>
                        <td>{{ item.totalNetto | currency }}</td>
                        <td>{{ item.salesTarget | currency }}</td>

                        <!-- Percent -->
                        <td v-if="!item.salesTarget > 0"></td>
                        <td v-else>
                            <v-progress-linear style="margin-top: 10px;" color="success" :value="item.salesTargetPercent" height="8"></v-progress-linear>
                            <p class="text-center" style="margin-bottom: 0;">{{ item.salesTargetPercent }}%</p>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "UsersDailyReport",

        data() {
            return {
                loading: true,
                data: [],
                tableHeaders: [
                    { text: '#', value: 'id' },
                    { text: this.$t('settings.lang_operator'), value: 'name' },
                    { text: this.$t('analytics.lang_turnoverGross'), value: 'totalNetto' },
                    { text: this.$t('analytics.lang_turnoverNet'), value: 'totalNetto' },
                    { text: this.$t('generic.lang_target'), value: 'salesTarget' },
                    { text: this.$t('analytics.lang_achievedInPercent'), value: 'salesTargetPercent' },
                ],
            }
        },

        computed: {
            ...mapState([
                'api'
            ])
        },

        mounted() {
            this.getUsersReport()
        },

        methods: {
            getUsersReport() {
                this.loading = true;

                this.axios.post("get/dashboard/usersDailyReport/").then((res) => {
                    if(res.data.success) {
                        this.data = res.data.data;
                    }

                    this.loading = false;
                });
            }
        }
    }
</script>