<template>
    <div>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-card-title>{{ $t('generic.lang_dailyOverview') }}</v-card-title>
                    <v-card-subtitle>{{ $t('generic.lang_eachCheckoutIndividually') }}</v-card-subtitle>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn :disabled="loading" :loading="loading" @click="getCashiersReport" icon>
                    <v-icon>refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <v-divider style="margin-top: 5px;"></v-divider>

            <v-data-table
                    :loading="loading"
                    :headers="tableHeaders"
                    :items="data"
                    :items-per-page="30"
                    class="elevation-1"
                    hide-default-footer
            >
                <template v-slot:item="{ item }">
                    <tr :class="customRowClass(item)">
                        <td>{{ item.cashierID }}</td>
                        <td>{{ item.totalBrutto | currency }}</td>
                        <td>{{ item.totalNetto | currency }}</td>
                        <td>{{ waresPerCustomer(item.soldItems, item.invoices) }}</td>
                        <td>{{ item.totalCash | currency }}</td>
                        <td>{{ item.totalCard | currency }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "SingleCashierDailyReport",

        data() {
            return {
                loading: true,
                data: [],
                tableHeaders: [
                    { text: this.$t('generic.lang_cashierID'), value: 'cashierID' },
                    { text: this.$t('analytics.lang_turnoverGross'), value: 'totalBrutto' },
                    { text: this.$t('analytics.lang_turnoverNet'), value: 'totalNetto' },
                    { text: this.$t('generic.lang_item_customer'), value: 'soldItems' },
                    { text: this.$t('generic.lang_totalInEuro'), value: 'totalCash' },
                    { text: this.$t('generic.lang_ecCardUmsaetze'), value: 'totalCard' },
                ],
            }
        },

        computed: {
            ...mapState([
                'api'
            ])
        },

        mounted() {
            this.getCashiersReport()
        },

        methods: {
            getCashiersReport() {
                this.loading = true;

                this.axios.post("get/dashboard/singleCashiersDaily/").then((res) => {
                    if(res.data.success) {
                        this.data = res.data.data;
                    }

                    this.loading = false;
                });
            },
            waresPerCustomer(soldItems, invoices) {
                if(soldItems === 0 || invoices === 0)
                    return 0;

                return Math.floor((soldItems / invoices));
            },
            customRowClass(item) {
                return item.cashierID === this.api.auth.cashierID ? 'activeCashier' : ''
            }
        }
    }
</script>

<style scoped>
    .activeCashier {
        font-weight: bold;
        background-color: #ffd08a;
    }
</style>